module.exports = {
  siteTitle: 'Ziben Labs',
  siteDescription: 'On-demand developers quickly available for your project.',
  siteUrl: 'https://www.zibenlabs.com',
  siteImage: '/logo.jpg',
  siteTwitterUsername: '@zibenlabs',
  manifestName: 'Ziben Labs',
  manifestShortName: 'Ziben Labs', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  // pathPrefix: `/portfolio/`, // This path is subpath of your hosting https://domain/portfolio
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'social_linkedin',
      url: 'https://www.linkedin.com/company/zibenlabs/',
    },
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'social_twitter',
      url: 'https://twitter.com/zibenlabs/',
    },
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'social_facebook',
      url: 'https://www.facebook.com/zibenlabs/',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'social_instagram',
      url: 'https://www.instagram.com/zibenlabs/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'social_email',
      url: 'mailto:ibnclaudius@zibenlabs.com',
    },
  ],
  googleAnalyticsTrackingId: 'UA-172023724-1',
};
