import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import config from '../../config';

const Footer = ({ intl }) => (
  <footer id="footer">
    <ul className="icons">
      {config.socialLinks.map((social) => {
        const { style, icon, name, url } = social;
        return (
          <li key={url}>
            <OutboundLink
              href={url}
              target="_blank"
              rel="noreferrer"
              className={`icon ${style} ${icon}`}
            >
              <span className="label">{intl.formatMessage({ id: name })}</span>
            </OutboundLink>
          </li>
        );
      })}
    </ul>
    <ul className="copyright">
      <li>&copy; Ziben Labs</li>
    </ul>
  </footer>
);

export default injectIntl(Footer);
