import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';

import config from '../../config';
import websiteIcon from '../assets/images/website-icon.png';

const SEO = ({ intl, title }) => (
  <Helmet
    htmlAttributes={{ lang: `${intl.formatMessage({ id: 'lang' })}` }}
    title={title}
    titleTemplate={`%s ― ${intl.formatMessage({ id: 'title' })}`}
    meta={[
      {
        property: `google-site-verification`,
        content: `FP7spjpt_XymfysfSoCreH4CibNxclaEePhNGF3DSpM`,
      },
      {
        property: `title`,
        content: `${intl.formatMessage({ id: 'title' })}`,
      },
      {
        name: `description`,
        content: `${intl.formatMessage({ id: 'description' })}`,
      },
      {
        property: `og:url`,
        content: config.siteUrl,
      },
      {
        property: `og:title`,
        content: `${intl.formatMessage({ id: 'title' })}`,
      },
      {
        property: `og:description`,
        content: `${intl.formatMessage({ id: 'description' })}`,
      },
      {
        property: `og:image`,
        content: `${config.siteUrl}${config.siteImage}`,
      },
      {
        property: `og:image:width`,
        content: `1200`,
      },
      {
        property: `og:image:height`,
        content: `630`,
      },
      {
        property: `og:image:alt`,
        content: config.siteTitle,
      },
      {
        name: `twitter:title`,
        content: `${intl.formatMessage({ id: 'title' })}`,
      },
      {
        name: `twitter:description`,
        content: `${intl.formatMessage({ id: 'description' })}`,
      },
      {
        property: `twitter:image`,
        content: `${config.siteUrl}${config.siteImage}`,
      },
      {
        property: `twitter:image:alt`,
        content: config.siteTitle,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
    ]}
    link={[{ rel: 'icon', type: 'image/png', href: `${websiteIcon}` }]}
  />
);

export default injectIntl(SEO);
